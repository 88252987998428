export const WORDS = [
    'hotty',
    'parks',
    'games',
    'music',
    'kevin',
    'randi',
    'jason',
    'denny',
    'suits',
    'today',
    'kvjtv',
    'phone',
    'check',
    'songs',
    'worky',
    'funny',
    'drive',
    'title',
    'beach',
    'south',
    'album',
    'words',
    'light',
    'games',
    'shawn',
    'adele',
    'story',
    'smith',
    'nsync',
    'perry',
    'miami',
    'lakes',
    'gator',
    'crane',
    'night',
    'shark',
    'fishy',
    'celeb',
    'sunny',
    'north',
    'ports',
    'train',
    'flags',
    'ocean',
    'worth',
    'gecko',
    'drink',
    'boats',
    'plays',
    'trees',
    'palms',
    'nikki',
    'jonas',
    'limes',
    'sandy',
    'dines',
    'bruno',
    'pinky',
    'pools',
    'towel',
    'humid',
    'money',
    'radio',
    'local',
    'vacay',
    'inbox',
    'lanes',
    'cafes',
    'dance',
    'swift',
    'pilot',
    'hotel',
    'photo',
    'glare',
    'court',
    'blues',
    'decor',
    'eagle',
    'paper',
    'light',
    'sushi',
    'visor',
    'risky',
    'seats',
    'enter',
    'jbird',
    'stone',
    'patio',
    'laugh',
    'adult',
    'after',
    'clean',
    'color',
    'shops',
    'kayak',
    'board',
]
